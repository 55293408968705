import Navigo from "navigo";

import Animation from "./Components/Animation/Animation";

const router = new Navigo("/");

router.on("/", () => {
	Animation();
});

router.notFound(() => true);

router.resolve();
