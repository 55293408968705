import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PortfolioSlider } from '../Slider/PortfolioSlider';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  gsap.defaults({
    ease: 'power4.inOut',
    duration: 0.3,
  });

  const heroAnimation = () => {
    const navigation = document.querySelector("[hero='navigation']");
    const heading = document.querySelector("[hero='heading']");
    const subheading = document.querySelector("[hero='subheading']");

    const heroTimeline = gsap.timeline();

    heroTimeline
      .to(navigation, {
        opacity: 1,
        delay: 0.5,
      })
      .to(heading, {
        opacity: 1,
      })
      .to(subheading, {
        opacity: 1,
      });
  };

  const definitionAnimation = () => {
    const heading = document.querySelector("[definition='heading']");
    const text1 = document.querySelector("[definition='text-1']");
    const text2 = document.querySelector("[definition='text-2']");

    const definitionTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "[definition='section']",
        start: 'top-=22.5% center',
        end: 'top end',
        scrub: false,
        markers: false,
      },
    });

    definitionTimeline
      .to(heading, {
        opacity: 1,
      })
      .to(text1, { opacity: 1 })
      .to(text2, { opacity: 1 });
  };

  const serviceAnimation = () => {
    const serviceTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "[service='section']",
        start: 'top-=25% center',
        scrub: false,
        markers: false,
      },
    });

    serviceTimeline
      .from("[service='heading']", {
        y: 50,
        opacity: 0,
      })
      .from("[service='item']", {
        y: 50,
        opacity: 0,
        stagger: 0.1,
      });
  };

  const portfolioAnimation = () => {
    const portfolioTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "[portfolio='section']",
        start: 'top-=25% center',
        scrub: false,
        markers: false,
      },
    });

    portfolioTimeline
      .from("[portfolio='heading']", {
        y: 50,
        opacity: 0,
      })
      .from("[portfolio='slider-container']", {
        opacity: 0,
      })
      .from(
        '.splide__arrows',
        {
          opacity: 0,
        },
        '<'
      );
  };

  const aboutAnimation = () => {
    const aboutTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "[about='section']",
        start: 'top-=25% center',
        scrub: false,
        markers: false,
      },
    });

    aboutTimeline
      .from("[about='text']", {
        y: 50,
        opacity: 0,
        stagger: 0.1,
      })
      .from("[about='image']", {
        opacity: 0,
      });
  };

  const expertiseAnimation = () => {
    const expertiseTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: "[expertise='section']",
        start: 'top center',
        scrub: false,
        markers: false,
      },
    });

    expertiseTimeline
      .from("[expertise='heading']", {
        y: 50,
        opacity: 0,
      })
      .from("[expertise='item']", {
        y: 50,
        opacity: 0,
        stagger: 0.1,
      });
  };

  // heroAnimation();
  // definitionAnimation();
  // serviceAnimation();
  // aboutAnimation();
  // expertiseAnimation();

  PortfolioSlider.on('ready', () => {
    // portfolioAnimation();
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 2000);
  });

  PortfolioSlider.on('visible', () => {
    ScrollTrigger.refresh();
  });

  PortfolioSlider.mount();

  document.querySelectorAll('[fs-accordion-element="accordion"]').forEach((element) => {
    element.addEventListener('click', () => {
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 2000);
    });
  });
};
